import { useState, useEffect } from "react";
import { useFormik } from "formik";
import api from "../services/api";
import { useNavigate } from "react-router-dom";
import { Drivers, Car, CarCategories, Configurations } from "../utils/commonTypes";
import { toast } from 'react-toastify';

export interface SimpleRace {
  id: number;
  name: string;
  date: string;
}

const CreateCarForm = () => {
  const [submitError, setSubmitError] = useState<string | null>(null);
  const navigate = useNavigate();
  const [loadingDrivers, setLoadingDrivers] = useState(true);
  const [drivers, setDrivers] = useState<Drivers[]>([]);
  const [loadingRaces, setLoadingRaces] = useState(true);
  const [races, setRaces] = useState<SimpleRace[]>([]);
  const [loadingCars, setLoadingCars] = useState(true);
  const [cars, setCars] = useState<Car[]>([]);
  const [loadingCarCategories, setLoadingCarCategories] = useState(true);
  const [carCategories, setCarCategories] = useState<CarCategories[]>([]);
  const [loadingCarConfigurations, setLoadingCarConfigurations] = useState(true);
  const [carConfigurations, setCarConfigurations] = useState<Configurations[]>([]);

  const [initialValues] = useState({
    event_id: '',
    default_driver_id: '',
    car_id: '',
    car_category_id: '',
    car_configuration_id: '',
  });

  useEffect(() => {
    const fetchDrivers = async () => {
      try {
        const response = await api.getAllDrivers();
  
        const driversData: Drivers[] = response.data.map((item: any) => ({
          id: item.id,
          name: item.name,
          surname: item.surname,
          number: item.number,
        }));
  
        setDrivers(driversData);
  
      } catch (error) {
        console.error('Error fetching drivers:', error);
      } finally {
        setLoadingDrivers(false);
      }
    };
  
    fetchDrivers();
  }, []);

  useEffect(() => {
    const fetchRaces = async () => {
      try {
        const response = await api.getAllRaces();
        
        const racesData: SimpleRace[] = (response.data as unknown as SimpleRace[]).map((item) => ({
          id: item.id,
          name: item.name,
          date: item.date,
        }));
  
        setRaces(racesData);
  
      } catch (error) {
        console.error('Error fetching races:', error);
      } finally {
        setLoadingRaces(false);
      }
    };
  
    fetchRaces();
  }, []);

  useEffect(() => {
    const fetchCars = async () => {
      try {
        const response = await api.getAllCars();
        
        const carsData: Car[] = (response.data as unknown as Car[]).map((item) => ({
          id: item.id,
          maker: item.maker,
          type: item.type,
          note: item.note,
          default_driver_id: item.default_driver_id,
        }));
  
        setCars(carsData);
  
      } catch (error) {
        console.error('Error fetching cars:', error);
      } finally {
        setLoadingCars(false);
      }
    };
  
    fetchCars();
  }, []);

  useEffect(() => {
    const fetchCarCategories = async () => {
      try {
        const response = await api.getCarCategories();
        
        const carCategoriesData: CarCategories[] = (response.data.data as unknown as CarCategories[]).map((item) => ({
          id: item.id,
          name: item.name,
          description: item.description,
        }));
  
        setCarCategories(carCategoriesData);
  
      } catch (error) {
        console.error('Error fetching car categories:', error);
      } finally {
        setLoadingCarCategories(false);
      }
    };
  
    fetchCarCategories();
  }, []);

  useEffect(() => {
    const fetchCarConfigurations = async () => {
      try {
        const response = await api.getCarConfigurations();
        
        const carConfigurationsData: Configurations[] = (response.data as unknown as Configurations[]).map((item) => ({
          id: item.id,
          note: item.note,
          power: item.power,
          weight: item.weight,
          power_weight_ratio: item.power_weight_ratio,
          aero_upgrade: item.aero_upgrade,
          excessive_modifications: item.excessive_modifications,
          excessive_chamber: item.excessive_chamber,
          liquid_leakage: item.liquid_leakage,
          rear_lights: item.rear_lights,
          safe: item.safe,
          street_legal_tires: item.street_legal_tires,
          seat_seatbelt_cage: item.seat_seatbelt_cage,
          widebody: item.widebody,
          wide_tires: item.wide_tires,
        }));
  
        setCarConfigurations(carConfigurationsData);
  
      } catch (error) {
        console.error('Error fetching car configurations:', error);
      } finally {
        setLoadingCarConfigurations(false);
      }
    };
  
    fetchCarConfigurations();
  }, []);
  
  
  

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      try {
        await api.eventRegistration(Number(values.event_id), Number(values.default_driver_id), Number(values.car_id), Number(values.car_category_id), Number(values.car_configuration_id));
        toast.success('Registrace byla úspěšně vytvořena!');
        navigate('/table-registrations');
      } catch (error: any) {
        console.error('API Error:', error.response);
        if (error.response && error.response.data) {
          console.error('Response data:', error.response.data);
        }
        setSubmitError(error.response?.data?.message || 'Nepodařilo se vytvořit novou sérii.');
      }
    },
    validate: (values) => {
      const errors: any = {};

      if (!values.event_id) {
        errors.event_id = "Vyplňte závod";
      }
      if (!values.default_driver_id) {
        errors.default_driver_id = "Vyberte řidiče";
      }
      if (!values.car_id) {
        errors.car_id = "Vyberte auto";
      }
      if (!values.car_category_id) {
        errors.car_category_id = "Vyberte kategorii auta";
      }
      if (!values.car_configuration_id) {
        errors.car_configuration_id = "Vyberte konfiguraci auta";
      }

      return errors;
    },
  });

  return (
    <div className="flex flex-col lg:flex-row w-screen pl-0 lg:pl-10 pt-10 items-center justify-center">
      <div className="flex items-center justify-center px-4">
        <form onSubmit={formik.handleSubmit}>
          <div className="w-full text-center lg:text-left">
            <h1 className="font-body text-5xl font-medium py-2">
              Vytvoření registrace do závodu
            </h1>
          </div>

          <div className="flex flex-col font-body my-1 custom-select">
            <label htmlFor="event_id" className="text-black">
              Závod
            </label>
            <select
              name="event_id"
              id="event_id"
              onChange={formik.handleChange}
              value={formik.values.event_id}
              className="custom-select rounded-md border border-gray-300 font-light py-1 pl-1 pr-4 focus:outline-none focus:border focus:border-gray-300 bg-white"
              disabled={loadingRaces}
            >
              <option value="">-- Vyberte závod --</option>
              {loadingRaces ? (
                <option>Načítání ...</option>
              ) : (
                races.map((race) => (
                  <option key={race.id} value={race.id}>
                    {race.name} | {new Date(race.date).toLocaleDateString('cs-CZ')}
                  </option>
                ))
              )}
            </select>

            {formik.errors.event_id ? (
              <div className="text-red-600">{formik.errors.event_id}</div>
            ) : null}
          </div>
          
          <div className="flex flex-col font-body my-1 custom-select">
            <label htmlFor="driver" className="text-black">
              Řidič
            </label>
            <select
              name="default_driver_id"
              id="driver"
              onChange={formik.handleChange}
              value={formik.values.default_driver_id}
              className="custom-select rounded-md border border-gray-300 font-light py-1 pl-1 pr-4 focus:outline-none focus:border focus:border-gray-300 bg-white"
              disabled={loadingDrivers}
            >
              <option value="">-- Vyberte řidiče --</option>
              {loadingDrivers ? (
                <option>Načítání ...</option>
              ) : (
                drivers.map((driver) => (
                  <option key={driver.id} value={driver.id}>
                    {driver.name} {driver.surname} | {driver.number}
                  </option>
                ))
              )}
            </select>

            {formik.errors.default_driver_id ? (
              <div className="text-red-600">{formik.errors.default_driver_id}</div>
            ) : null}
          </div>

          <div className="flex flex-col font-body my-1 custom-select">
            <label htmlFor="car_id" className="text-black">
              Auto
            </label>
            <select
              name="car_id"
              id="car_id"
              onChange={formik.handleChange}
              value={formik.values.car_id}
              className="custom-select rounded-md border border-gray-300 font-light py-1 pl-1 pr-4 focus:outline-none focus:border focus:border-gray-300 bg-white"
              disabled={loadingCars}
            >
              <option value="">-- Vyberte auto --</option>
              {loadingCars ? (
                <option>Načítání ...</option>
              ) : (
                cars.map((car) => (
                  <option key={car.id} value={car.id}>
                    {car.maker} {car.type} | {drivers.find((driver) => driver.id === car.default_driver_id)?.name} {drivers.find((driver) => driver.id === car.default_driver_id)?.surname}
                  </option>
                ))
              )}
            </select>

            {formik.errors.car_id ? (
              <div className="text-red-600">{formik.errors.car_id}</div>
            ) : null}
          </div>

            <div className="flex flex-col font-body my-1 custom-select">
              <label htmlFor="car_category_id" className="text-black">
                Kategorie auta
              </label>
              <select
                name="car_category_id"
                id="car_category_id"
                onChange={formik.handleChange}
                value={formik.values.car_category_id}
                className="custom-select rounded-md border border-gray-300 font-light py-1 pl-1 pr-4 focus:outline-none focus:border focus:border-gray-300 bg-white"
                disabled={loadingCarCategories}
              >
                <option value="">-- Vyberte kategorii --</option>
                {loadingCarCategories ? (
                  <option>Načítání ...</option>
                ) : (
                  carCategories.map((car) => (
                    <option key={car.id} value={car.id}>
                      {car.name} | {car.description}
                    </option>
                  ))
                )}
              </select>

              {formik.errors.car_category_id ? (
              <div className="text-red-600">{formik.errors.car_category_id}</div>
            ) : null}
            </div>

            <div className="flex flex-col font-body my-1 custom-select">
            <label htmlFor="car_configuration_id" className="text-black">
              Konfigurace auta
            </label>
            <select
              name="car_configuration_id"
              id="car_configuration_id"
              onChange={formik.handleChange}
              value={formik.values.car_configuration_id}
              className="custom-select rounded-md border border-gray-300 font-light py-1 pl-1 pr-4 focus:outline-none focus:border focus:border-gray-300 bg-white"
              disabled={loadingCarConfigurations}
            >
              <option value="">-- Vyberte konfiguraci --</option>
              {loadingCarConfigurations ? (
                <option>Načítání ...</option>
              ) : (
                carConfigurations.map((car) => (
                  <option key={car.id} value={car.id}>
                    {car.note}
                  </option>
                ))
              )}
            </select>

            {formik.errors.car_configuration_id ? (
              <div className="text-red-600">{formik.errors.car_configuration_id}</div>
            ) : null}
          </div>

          <div className="flex justify-center font-body my-3">
            <button
              type="submit"
              className="bg-emerald-500 text-white py-1 px-8 hover:px-10 duration-100 rounded-full active:bg-emerald-700"
            >
              Vytvořit
            </button>
          </div>

          {submitError && (
            <div className="text-red-600 text-center mt-4">{submitError}</div>
          )}
        </form>
      </div>
      </div>
  );
};

export default CreateCarForm;
