import { useEffect, useState } from 'react';
import { Races } from '../utils/commonTypes';
import api from '../services/api';
import { useAuth } from '../contexts/AuthContext';
import { NavLink } from 'react-router-dom';

const TableOfRaces = ({ searchQuery }: { searchQuery: string }) => {
    const [results, setResults] = useState<Races[]>([]);
    const { isAuthenticated } = useAuth();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        api.getAllRaces()
            .then((response) => {
                const races = response.data as unknown as Races[];

                setResults(races);

                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching races:', error);
            });
    }, []);

    const filteredData = results.filter((result) =>
        result.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        result.location.toLowerCase().includes(searchQuery.toLowerCase()) ||
        result.number_of_laps.toString().includes(searchQuery) ||
        new Date(result.date).toLocaleString().toLowerCase().includes(searchQuery.toLowerCase()) ||
        result.series_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        result.phase_name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="overflow-x-auto">
            {isLoading && (
                <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75 z-10">
                    <span className="text-xl font-body">Načítání ...</span>
                </div>
            )}
            <table className="w-full table-auto border-separate font-body" style={{ borderSpacing: '0 3px' }}>
                <thead className="bg-red-600">
                    <tr>
                        <th className="px-6 py-4 text-left text-xs font-medium text-white uppercase tracking-wider rounded-tl-lg">Jméno</th>
                        <th className="px-6 py-4 text-left text-xs font-medium text-white uppercase tracking-wider">Počet kol</th>
                        <th className="px-6 py-4 text-left text-xs font-medium text-white uppercase tracking-wider">Datum</th>
                        <th className="px-6 py-4 text-left text-xs font-medium text-white uppercase tracking-wider">Lokace</th>
                        <th className="px-6 py-4 text-left text-xs font-medium text-white uppercase tracking-wider">Název série</th>
                        <th className="px-6 py-4 text-left text-xs font-medium text-white uppercase tracking-wider">Fáze závodu</th>
                        {isAuthenticated && (
                            <>
                                <th className="px-6 py-4 text-left text-xs font-medium text-white uppercase tracking-wider"></th>
                            </>
                        )}
                        <th className="px-6 py-4 text-left text-xs font-medium text-white uppercase tracking-wider rounded-tr-lg"></th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-gray-200">
                    {filteredData.map((result, index) => (
                        <tr key={index} className='bg-zinc-800 text-white'>
                            <td className="px-6 py-2 whitespace-nowrap">{result.name}</td>
                            <td className="px-6 py-2 whitespace-nowrap">{result.number_of_laps}</td>
                            <td className="px-6 py-2 whitespace-nowrap">{new Date(result.date).toLocaleDateString('cs-CZ', { day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/\s/g, '')}</td>
                            <td className="px-6 py-2 whitespace-nowrap">{result.location}</td>
                            <td className="px-6 py-2 whitespace-nowrap">{result.series_name}</td>
                            <td className="px-6 py-2 whitespace-nowrap">{result.phase_name}</td>
                            <td className="px-6 py-2 whitespace-nowrap">
                                <button className='bg-blue-500 rounded-full px-4 py-1 hover:bg-blue-600 duration-100 active:bg-blue-700' id={result.id.toString()}>Výsledky</button>
                            </td>
                            {isAuthenticated && (
                                <>
                                    <td className="px-6 py-2 whitespace-nowrap">
                                        <NavLink to={`/edit-race/${result.id}`}>
                                            <button className='bg-green-400 rounded-full px-4 py-1 hover:bg-green-500 duration-100 active:bg-green-700' id={result.id.toString()}>Upravit</button>
                                        </NavLink>
                                    </td>
                                </>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default TableOfRaces;